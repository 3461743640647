import * as React from "react";
import styled from "styled-components";

import { LegendElement } from "api";

import themes from "components/themes";
import { Dot } from "components/Dot";
import { PositionLine } from "./PositionLine/PositionLine";
import { IframeGradient } from "./IframeGradient/IframeGradient";
import { toLocaleNumber } from "utils/numbers";

// -- TYPES
interface Payload {
  value: number;
  [property: string]: string | number;
}

type ChartTooltipProps = {
  label?: string;
  payload?: Payload[];
  legendElements?: LegendElement[] | null;
  renderVerticalPositionLine?: boolean;
  renderIframeGradient?: boolean;
};

// -- COMPONENT
function SimpleChartTooltip({
  label,
  payload,
  legendElements,
  renderVerticalPositionLine = false,
  renderIframeGradient = false,
}: ChartTooltipProps): JSX.Element {
  const renderData = () => {
    if (legendElements && payload) {
      return legendElements.map((legendElement: LegendElement) => {
        const styledDot = {
          backgroundColor: legendElement.color,
        };

        const data = payload.find(
          (p: any) => p.dataKey === legendElement.dataIndex,
        );

        return (
          <DataWrapper key={legendElement.label || "legend-column"}>
            {legendElement.label && data && (
              <>
                <StyledDot style={styledDot} />
                {toLocaleNumber(Number(data.value), {
                  numberOfDecimalPlaces: 1,
                })}
                % of visitors scroll Control to {label} of the page
              </>
            )}
          </DataWrapper>
        );
      });
    }
  };

  const iframeGradientHeight = React.useMemo(() => {
    const labelValue = Number(label ? label.replace("%", "") : 0);

    return labelValue;
  }, [label]);

  return (
    <TooltipWrapper>
      {payload?.length && (
        <>
          {legendElements && renderData()}
          {renderVerticalPositionLine && <PositionLine />}
          {renderIframeGradient && (
            <IframeGradient height={iframeGradientHeight} />
          )}
        </>
      )}
    </TooltipWrapper>
  );
}

// -- STYLES
const TooltipWrapper = styled.div`
  padding: 10px 16px;
  border-radius: 4px;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    linear-gradient(
      239deg,
      ${themes.colors.chartDarkPurple},
      ${themes.colors.chartLightPurple}
    );
  box-shadow: 0 20px 40px 0 rgba(2, 2, 19, 0.4);
  display: flex;
  display: -ms-grid;
  -ms-grid-columns: min-content;
  flex-direction: column;
  width: max-content;

  * {
    font-size: 12px;
  }
`;

const StyledDot = styled(Dot)`
  margin-right: 4px;
`;

const DataWrapper = styled.div`
  min-height: 21px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
`;

export default React.memo(SimpleChartTooltip);
