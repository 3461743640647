import React from "react";
import styled from "styled-components";
import { isArray, isPlainObject, get } from "lodash";
import moment from "moment";

import { ColumnType, RowType, AvailableDisplayModes } from "./constants/types";
import {
  LegendElement,
  ChartTooltipRowLabels,
  ReferenceLine,
  ReferenceLinesTypes,
  PayloadContent,
} from "api/models";

import { createTooltipReferenceLineGroup } from "./helpers";

import themes from "components/themes";
import { Dot } from "components/Dot";
import { valueOrNA } from "../../utils/valueOrNA";
import { periodStartDateFormatter } from "utils/charts";
import { toLocaleNumber, percentFormat, checkIsNumber } from "utils/numbers";

// -- TYPES
interface PayloadShape {
  payload: PayloadContent;
}

interface ChartTooltipProps {
  label?: string;
  payload?: PayloadShape[];
  aggregatedData?: PayloadContent[];
  legendElements?: LegendElement[] | null;
  displayMode: AvailableDisplayModes;
  referenceLines?: ReferenceLine[] | undefined;
}

function formatTooltipValue(
  columnLabel: string,
  singleTooltipColumn: any,
  rowLabel: string,
) {
  let value = null;
  if (rowLabel === "cvr") {
    if (singleTooltipColumn?.tooltipCvr.visits) {
      value = percentFormat(
        singleTooltipColumn.tooltipCvr.conversions /
          singleTooltipColumn.tooltipCvr.visits,
      );
    }
  } else {
    const rowValue = singleTooltipColumn[rowLabel];
    value =
      rowValue !== null && checkIsNumber(Number(rowValue))
        ? toLocaleNumber(Number(rowValue))
        : rowValue;
  }

  if (columnLabel === "frontrunner") {
    value = valueOrNA(value);
  }

  return value ?? 0;
}

// -- COMPONENT
const ChartTooltip: React.FC<ChartTooltipProps> = React.memo(
  ({
    label,
    payload,
    aggregatedData,
    legendElements,
    displayMode,
    referenceLines,
  }) => {
    const renderColumnRows = (
      columnDataIndex: string,
      columnStyle: React.CSSProperties = {},
      rows: RowType[],
    ): React.ReactNode =>
      rows.map((singleRow, index) => {
        const valueToRender = singleRow[columnDataIndex];
        const formattedValue = isNaN(Number(valueToRender))
          ? valueToRender
          : toLocaleNumber(valueToRender as number);

        return (
          <Cell
            key={`cell-${columnDataIndex}-${index}`}
            style={isPlainObject(columnStyle) ? columnStyle : {}}
          >
            {formattedValue}
          </Cell>
        );
      });

    const renderColumns = (
      columns: ColumnType[],
      columnRows: RowType[],
    ): React.ReactNode => {
      if (!isArray(columns) || !isArray(columnRows)) {
        return null;
      }

      return columns.map((singleColumn: ColumnType) => (
        <Column key={singleColumn.label || "legend-column"}>
          <Cell
            style={
              isPlainObject(singleColumn.labelStyle)
                ? singleColumn.labelStyle
                : {}
            }
          >
            {singleColumn.label && (
              <>
                <StyledDot
                  style={
                    isPlainObject(singleColumn.dotStyle)
                      ? singleColumn.dotStyle
                      : {}
                  }
                />
                {singleColumn.label}
              </>
            )}
          </Cell>
          {renderColumnRows(
            singleColumn.dataIndex,
            singleColumn.columnStyle,
            columnRows,
          )}
        </Column>
      ));
    };

    const prepareTooltipData = (): [ColumnType[], RowType[]] | [] => {
      if (!isArray(payload) || !isArray(legendElements)) {
        return [];
      }

      const tooltipGridColumns: ColumnType[] = [
        {
          dataIndex: "label",
          columnStyle: {
            fontSize: "12px",
            color: themes.colors.lightGrey,
          },
        },
      ];

      // creating columns
      legendElements.forEach((legendElement) => {
        if (legendElement.isReferenceLine) {
          return null;
        }

        const dataObjectIndex = legendElement.dataIndex.slice(
          0,
          legendElement.dataIndex.indexOf("."),
        );
        // adding single column
        tooltipGridColumns.push({
          dataIndex: dataObjectIndex,
          label: legendElement.label,
          labelStyle: {
            fontWeight: 600,
          },
          dotStyle: {
            backgroundColor: legendElement.color,
          },
          columnStyle: {
            fontWeight: 600,
          },
        });
      });

      let payloadData: any = null;
      if (aggregatedData && aggregatedData.length > 0) {
        payloadData = aggregatedData.find(
          (chartElement) =>
            chartElement.label ===
            periodStartDateFormatter(label || "", displayMode),
        );
      } else {
        payloadData = get(payload, "[0].payload");
      }

      if (!payloadData) {
        return [];
      }

      const rowsData: any = {};

      Object.keys(payloadData).forEach((singlePayloadKey) => {
        const singlePayloadValue = payloadData[singlePayloadKey];

        if (
          singlePayloadKey === "label" ||
          !isPlainObject(singlePayloadValue)
        ) {
          return null;
        }

        Object.keys(singlePayloadValue).forEach((payloadValueKey) => {
          if (payloadValueKey === "tooltipCvr") {
            return null;
          }
          rowsData[payloadValueKey] = {
            ...(rowsData[payloadValueKey] || {}),
            [singlePayloadKey]: `${formatTooltipValue(
              singlePayloadKey,
              singlePayloadValue,
              payloadValueKey,
            )}`,
          };
        });
      });

      const rows: RowType[] = Object.keys(rowsData).map((rowKey: string) => {
        return {
          label:
            ChartTooltipRowLabels[rowKey as "cvr" | "conversions" | "visits"],
          ...(rowsData[rowKey] || {}),
        };
      });

      return [tooltipGridColumns, rows];
    };

    const [tooltipColumns, tooltipRows] = prepareTooltipData();

    const displayTooltipFormattedLabel = (
      tooltipLabel: string = "",
    ): string => {
      if (displayMode === "week") {
        const startOfWeek = moment(tooltipLabel).startOf("isoWeek");
        const endOfWeek = moment(tooltipLabel).endOf("isoWeek");

        let startDateFormat = "MMM DD";
        let endDateFormat = "DD, YYYY";

        if (!startOfWeek.isSame(endOfWeek, "year")) {
          startDateFormat = "MMM DD, YYYY";
          endDateFormat = "MMM DD, YYYY";
        } else if (!startOfWeek.isSame(endOfWeek, "month")) {
          startDateFormat = "MMM DD";
          endDateFormat = "DD MMM, YYYY";
        }

        return `${startOfWeek.format(startDateFormat)} - ${endOfWeek.format(
          endDateFormat,
        )}`;
      }

      const momentDate = moment(tooltipLabel);

      if (displayMode === "month") {
        return momentDate.format("MMM, YYYY");
      }

      return momentDate.format("MMM DD, YYYY");
    };

    const renderReferenceLinesValues = (): React.ReactNode => {
      if (
        !isArray(legendElements) ||
        !isArray(referenceLines) ||
        !legendElements.find((singleElem) => singleElem.isReferenceLine)
      ) {
        return null;
      }

      const referenceLinesForPoint = referenceLines.filter(
        (refLine) =>
          periodStartDateFormatter(
            (refLine.axisValue as string) || "",
            displayMode,
          ) === periodStartDateFormatter(label || "", displayMode),
      );

      if (!referenceLinesForPoint.length) {
        return null;
      }

      const shipDates = createTooltipReferenceLineGroup(
        referenceLinesForPoint,
        ReferenceLinesTypes.shipDate,
        "Ship Dates",
      );
      const userActions = createTooltipReferenceLineGroup(
        referenceLinesForPoint,
        ReferenceLinesTypes.userAction,
        "User Actions",
      );

      return [shipDates, userActions].map((singleRefLineGroup) => {
        if (!singleRefLineGroup.values.length) {
          return null;
        }

        return (
          <div key={singleRefLineGroup.label}>
            <Title marginTop>{singleRefLineGroup.label}</Title>
            {singleRefLineGroup.values.map((singleValue) => (
              <ShipDate key={`${singleRefLineGroup.label}-${singleValue}`}>
                {singleValue}
              </ShipDate>
            ))}
          </div>
        );
      });
    };

    return (
      <TooltipWrapper>
        <Title>{displayTooltipFormattedLabel(label)}</Title>
        {tooltipColumns && tooltipRows && (
          <Grid>{renderColumns(tooltipColumns, tooltipRows)}</Grid>
        )}
        {renderReferenceLinesValues()}
      </TooltipWrapper>
    );
  },
);

// -- STYLES
const TooltipWrapper = styled.div`
  padding: 10px 16px;
  border-radius: 4px;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0.09)
    ),
    linear-gradient(
      239deg,
      ${themes.colors.chartDarkPurple},
      ${themes.colors.chartLightPurple}
    );
  box-shadow: 0 20px 40px 0 rgba(2, 2, 19, 0.4);
  display: flex;
  display: -ms-grid;
  -ms-grid-columns: min-content;
  flex-direction: column;
  width: max-content;

  * {
    font-size: 12px;
  }
`;
const Title = styled.h3<{ marginTop?: boolean }>`
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 10px;
  font-weight: 600;
  ${(props: any) => props.marginTop && `margin-top: 10px;`}
`;
const Grid = styled.div`
  display: flex;

  > div:not(:last-of-type) {
    margin-right: 20px;
  }
`;
const Column = styled.div`
  > div:last-of-type {
    margin-bottom: 0;
  }
`;
const Cell = styled.div`
  min-height: 21px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledDot = styled(Dot)`
  margin-right: 4px;
`;
const ShipDate = styled.div`
  margin-bottom: 4px;
  font-size: 12px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export default ChartTooltip;
