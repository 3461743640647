import { ApplicationAsset, AssetType } from "api/models";

import { errorMessageHandler } from "utils/errorHandlers/errorMessageHandler";

import { ModalSetter } from "contexts/modalContext";

import { GET_APPLICATION_CONTROL, GET_APPLICATION_OVERVIEW } from "api/queries";

export async function updateReferenceVariation(
  applicationId: string,
  asset: ApplicationAsset,
  type: AssetType,
  mutation: any,
  modalSetter: ModalSetter,
  refetchQuery: any,
  setFullPageLoader: (isVisible: boolean) => void,
) {
  const onSetTop = async () => {
    setFullPageLoader(true);
    const refetchQueries = [
      refetchQuery,
      {
        query: GET_APPLICATION_CONTROL,
        variables: { applicationId },
      },
      {
        query: GET_APPLICATION_OVERVIEW,
        variables: { applicationId },
      },
    ];

    try {
      await mutation({
        variables: {
          assetId: asset.id,
          assetType: type,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      });
    } catch (err) {
      modalSetter({
        type: "set",
        props: {
          title: "Error",
          content: errorMessageHandler(err),
        },
      });
    }
    setFullPageLoader(false);
  };
  modalSetter({
    type: "set",
    props: {
      title: "Set as Winner",
      content:
        "Are you sure you want to manually set this asset as the top asset and move forward to the next phase of testing? Please note that this will end the current phase of testing early and begin the next phase of testing for the next Element.",
      onConfirm: onSetTop,
    },
  });
}
