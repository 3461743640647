import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

interface DropdownItem {
  value: any;
  name: string;
}

interface Props {
  value?: any;
  name: string;
  placeholder: string;
  items: DropdownItem[];
  onChange: (value: any) => void;
}

function DropdownInput({
  value = "",
  name,
  items,
  placeholder,
  onChange,
}: Props) {
  return (
    <Dropdown>
      <Dropdown.Toggle id={name}>
        {value ? items.filter((o) => o.value === value)[0].name : placeholder}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map((item) => {
          return (
            <Dropdown.Item
              key={`${name}-${item.value}`}
              onSelect={() => onChange(item.value)}
              active={item.value === value}
            >
              {item.name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownInput;
