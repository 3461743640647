import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";

import { AssetType, HistoricalConversionData } from "api";

import themes from "./themes";

import { ModalContext } from "contexts";

import { Button } from "./forms";
import { AssetHistoricalDataTable } from "./AssetHistoricalDataTable";
import { DividerHorizontal, FloatingLabel, Icons, Tooltip } from "components";
import AssetCardActionIcon from "./AssetCardActionIcon";

interface Props {
  type?: AssetType;
  title: string;
  children: React.ReactNode;
  control?: boolean;
  onDelete?: (() => void) | null;
  onDeactivate?: (() => void) | null;
  onSetTop?: (() => void) | null;
  historicalData?: HistoricalConversionData[];
  previewUrl?: string;
}

function TextAssetCard({
  type,
  title,
  children,
  control = false,
  onDelete = null,
  onDeactivate = null,
  onSetTop = null,
  historicalData,
  previewUrl,
}: Props) {
  const [isHovered, setHovered] = useState(false);

  const setModal = useContext(ModalContext);

  const renderAssetType = (): string => {
    if (type === AssetType.description) {
      return "Description";
    }

    if (type === AssetType.subtitle) {
      return "Subtitle";
    }

    return "Title";
  };

  const handleAssetHistoryClick = () => {
    setModal({
      type: "set",
      props: {
        title: "Historical Data",
        width: 1000,
        content: historicalData ? (
          <AssetHistoricalDataTable historicalData={historicalData} />
        ) : (
          "No data"
        ),
      },
    });
  };

  return (
    <Container
      isHovered={isHovered}
      control={control}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Header isHovered={isHovered}>
          {isHovered ? (
            <Flex>
              {onSetTop && (
                <WinnerButton onClick={onSetTop} outline={true}>
                  Set as Winner
                </WinnerButton>
              )}

              <IconActionsWrapper>
                {historicalData && historicalData.length > 0 && (
                  <Tooltip content={"View historical data"} placement="top">
                    <AssetCardActionIcon
                      icon={Icons.faHistory}
                      color={themes.colors.white}
                      onClick={handleAssetHistoryClick}
                    />
                  </Tooltip>
                )}
                {previewUrl && (
                  <PreviewLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={previewUrl}
                  >
                    <AssetCardActionIcon
                      icon={Icons.faEye}
                      color={themes.colors.white}
                    />
                  </PreviewLink>
                )}
                {onDelete && (
                  <DeleteButton onClick={onDelete}>
                    <AssetCardActionIcon icon={Icons.faTrash} />
                  </DeleteButton>
                )}
                {onDeactivate && (
                  <DeleteButton onClick={onDeactivate}>
                    <AssetCardActionIcon icon={Icons.faTrash} />
                  </DeleteButton>
                )}
              </IconActionsWrapper>
            </Flex>
          ) : (
            renderAssetType()
          )}
        </Header>
      </Flex>
      <Title type={type}>{title}</Title>
      <Divider dark />

      {children}

      {control && <FloatingLabel>Current Control Creative</FloatingLabel>}
    </Container>
  );
}

const Container = styled.div<{ control: boolean; isHovered: boolean }>`
  position: relative;
  height: 100%;
  width: 412px;
  border-radius: 8px;
  background-color: ${(props: any) =>
    props.isHovered ? `#231a42` : `#16162f`};
  padding: 20px 30px 30px;
  color: ${themes.colors.white};
  border: ${(props) =>
    props.control ? `1px solid ${themes.colors.purple}` : null};
`;

const Title = styled.div<{ type?: AssetType }>`
  margin-top: 8px;
  font-size: 18px;
  font-weight: 600;
  ${(props: any) => props.type === AssetType.description && `height: 300px;`}
  overflow-y: auto;
`;

const WinnerButton = styled(Button)`
  font-size: 12px;
  height: 30px;
`;

const DeleteButton = styled.div`
  margin-left: 15px;
`;

const Header = styled.div<{ isHovered: boolean }>`
  font-size: 11px;
  font-weight: 300;
  ${(props: any) => !props.isHovered && `opacity: 0.75;`}
  height: 30px;
  width: 100%;
`;

const Divider = styled(DividerHorizontal)`
  margin: 20px 0;
  background: #47475b;
`;

const IconActionsWrapper = styled(Flex)`
  width: 100%;

  justify-content: flex-end;
  align-items: center;

  > * {
    line-height: 1;
  }
`;

const PreviewLink = styled.a`
  padding-left: 20px;
`;

export default TextAssetCard;
