import React from "react";
import styled from "styled-components";

import themes from "components/themes";

interface Props {
  children: React.ReactNode;
  center?: boolean;
}

function Card({ children, center = false }: Props) {
  return center ? <CenterBox>{children}</CenterBox> : <Box>{children}</Box>;
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  background-color: ${themes.colors.backgroundDark};
  box-shadow: 0 20px 40px 0 rgba(2, 2, 19, 0.4);
`;

const CenterBox = styled(Box)`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export default Card;
